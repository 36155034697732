import * as React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ReactNode} from 'react';

type ToolTipProps = {
    shown?: boolean;
    id: string;
    placement?: `auto` | `top` | `bottom` | `left` | `right`;
    title: string;
    children: ReactNode;
};

const ToolTip: React.FC<ToolTipProps> = ({children, shown = true, id, placement = `top`, title}) => {
    if (!shown) {
        return <span>{children}</span>;
    }

    /**
     * Template
     */
    return (
        <>
            <OverlayTrigger
                placement={placement}
                overlay={<Tooltip id={id}>{title}</Tooltip>}
            >
                <div className={'d-inline disabled-button-wrapper'}>{children}</div>
            </OverlayTrigger>
        </>
    );
};

export default ToolTip;
